@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: white;
    background: #111827;
/*  background: #4338ca;*/
}

@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=VT323&family=Roboto&display=swap");

.main-content {
  z-index: 10;
}

.scanlines {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  animation: opacity 3s linear infinite;
  z-index: -20;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.5) 51%
    );
    background-size: 100% 4px;
    will-change: background, background-size;
    animation: scanlines 0.2s linear infinite;
  }
}

.play-font {
  font-family: "Press Start 2P";
}

.play-font2 {
  font-family: "VT323";
  font-weight: 400;
  font-style: normal;
}

.play-font3 {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: normal;
}

.intro-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  font-size: 1rem;
  z-index: -20;
  /*  background: #2b52ff;*/

  .play {
    position: absolute;
    left: 2rem;
    top: 2rem;
    animation: rgbText 2s steps(9) 0s infinite alternate;
  }

  .time {
    position: absolute;
    right: 64px;
    top: 32px;
    animation: rgbText 1s steps(9) 0s infinite alternate;
  }

  .recordSpeed {
    position: absolute;
    left: 32px;
    bottom: 64px;
    animation: rgbText 1s steps(9) 0s infinite alternate;
  }
}

/* Animations */

@keyframes opacity {
  0% {
    opacity: 0.6;
  }
  20% {
    opacity: 0.3;
  }
  35% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  60% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes scanlines {
  from {
    background: linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.5) 51%
    );
    background-size: 100% 4px;
  }
  to {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 50%,
      transparent 51%
    );
    background-size: 100% 4px;
  }
}

@keyframes rgbText {
  0% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      0px 0 1px rgba(251, 0, 231, 0.8),
      0 0px 3px rgba(0, 233, 235, 0.8),
      0px 0 3px rgba(0, 242, 14, 0.8),
      0 0px 3px rgba(244, 45, 0, 0.8),
      0px 0 3px rgba(59, 0, 226, 0.8);
  }
  25% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      0px 0 1px rgba(251, 0, 231, 0.8),
      0 0px 3px rgba(0, 233, 235, 0.8),
      0px 0 3px rgba(0, 242, 14, 0.8),
      0 0px 3px rgba(244, 45, 0, 0.8),
      0px 0 3px rgba(59, 0, 226, 0.8);
  }
  45% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      5px 0 1px rgba(251, 0, 231, 0.8),
      0 5px 1px rgba(0, 233, 235, 0.8),
      -5px 0 1px rgba(0, 242, 14, 0.8),
      0 -5px 1px rgba(244, 45, 0, 0.8),
      5px 0 1px rgba(59, 0, 226, 0.8);
  }
  50% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      -5px 0 1px rgba(251, 0, 231, 0.8),
      0 -5px 1px rgba(0, 233, 235, 0.8),
      5px 0 1px rgba(0, 242, 14, 0.8),
      0 5px 1px rgba(244, 45, 0, 0.8),
      -5px 0 1px rgba(59, 0, 226, 0.8);
  }
  55% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      0px 0 3px rgba(251, 0, 231, 0.8),
      0 0px 3px rgba(0, 233, 235, 0.8),
      0px 0 3px rgba(0, 242, 14, 0.8),
      0 0px 3px rgba(244, 45, 0, 0.8),
      0px 0 3px rgba(59, 0, 226, 0.8);
  }
  90% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      -5px 0 1px rgba(251, 0, 231, 0.8),
      0 5px 1px rgba(0, 233, 235, 0.8),
      5px 0 1px rgba(0, 242, 14, 0.8),
      0 -5px 1px rgba(244, 45, 0, 0.8),
      5px 0 1px rgba(59, 0, 226, 0.8);
  }
  100% {
    text-shadow:
      -1px 1px 8px rgba(255, 255, 255, 0.6),
      1px -1px 8px rgba(255, 255, 235, 0.7),
      5px 0 1px rgba(251, 0, 231, 0.8),
      0 -5px 1px rgba(0, 233, 235, 0.8),
      -5px 0 1px rgba(0, 242, 14, 0.8),
      0 5px 1px rgba(244, 45, 0, 0.8),
      -5px 0 1px rgba(59, 0, 226, 0.8);
  }
}

@keyframes type {
  0%,
  19% {
    opacity: 0;
  }
  20%,
  100% {
    opacity: 1;
  }
}
